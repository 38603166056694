export const COGNITO = {
  REGION: 'us-east-1',
  USER_POOL_ID: 'us-east-1_rZV4H3EDo',
  APP_CLIENT_ID: '3h49dkkuut1jo6cmcmru9398g9',
};

export const MAIN_API = {
  name: 'asset-tracker-Api-J8KIWZ7OUBH',
  endpoint: 'https://api-dev.dat.cryptocurves.io',
};
